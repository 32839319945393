import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import jQuery from "jquery";

import { BsStar } from "@react-icons/all-files/bs/BsStar";
import { BsStarFill } from "@react-icons/all-files/bs/BsStarFill";

import ProjectDetailsCard from "../components/molecules/ProjectDetailsCard";
import ProjectDetailsOverview from "../components/molecules/ProjectDetailsOverview";
import RelatedProjects from "../components/molecules/RelatedProjects";
import LoadingSpinner from "../components/atoms/Spinner";
import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";
import PopUp from "../components/molecules/PopUp";

import { getCurrentUser, getCurrentUserRole } from "../services/AuthService";
import { getProjectById, deleteProject, getProjectsByUploaderId, getProfileByProjectId, getProjectOverviewById, getProjectDetailsById, updateProjectViews } from "../services/ProjectService";
import { sendFeedback, verifyProject } from "../services/AdminService";
import { getUserById } from "../services/UserService";

import { adminFeedbackValidation } from "../util/Validations";
import scrollTrigger from "../util/ScrollReveal";

import "../styles/pages/ProjectDetailPage.scss";

const socialInitialValues = {
  instagram: "",
  linkedin: "",
  facebook: "",
  pinterest: "",
};

const ProjectDetailPage = ({ user }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState(null);

  const [project, setProject] = useState({});
  const [projectDetails, setProjectDetails] = useState({});
  const [profile, setProfile] = useState({});
  const [teamMembers, setTeamMembers] = useState([""]);
  const [teamMembersLink, setTeamMembersLink] = useState([]);
  const [uploaderMail, setUploaderMail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modal, showModal] = useState(false);
  const [textarea, showTextarea] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [verified, setVerified] = useState(false);
  const [adminRating, setAdminRating] = useState(1);
  const [isProjectOwner, setIsProjectOwner] = useState(false);
  const [projectOwnerRole, setProjectOwnerRole] = useState("");
  const [deleteProjectPopup, showDeleteProjectPopup] = useState(false);
  const [errors, setErrors] = useState({});

  const adminRatingRange = 10;
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    // Scroll to top of project (android)
    window.scrollTo({ top: 0 });

    const email = getCurrentUser();

    getProjectOverviewById(id).then((projectOverview) => {
      if (projectOverview) {
        getProfileByProjectId(id).then((profile) => {
          setProject(projectOverview);
          setProfile(profile);
          setIsProjectOwner(profile.user.email === email);
          setProjectOwnerRole(profile.user.role);
          setAdminRating(projectOverview.adminRating);
          setIsLoading(false);
          
          if (profile.socials === undefined || profile.socials.length === 0) {
            profile.socials = [socialInitialValues];
          }
          
          if (projectOverview.teamMembers) {
            const projectMembers = projectOverview.teamMembers.split(",");
            projectMembers.map((member, i) => {
              getUserById(member).then((user) => {
                if (user !== null) {
                  teamMembers[i] = user.profile.firstName + " " + user.profile.lastName;
                  teamMembersLink[i] = user.uuid;
                } else {
                  teamMembers[i] = member;
                  teamMembersLink[i] = "";
                }
    
                setTeamMembers([...teamMembers]);
                setTeamMembersLink([...teamMembersLink]);
              });
            });
          }
        });

        if (email) {
          updateProjectViews(id);
        }
      } else {
        history.replace("/404");
      }
    });

  }, []);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      setRole(getCurrentUserRole());
    }
  }, [user]);

  useEffect(() => {
    if (currentUser && !jQuery.isEmptyObject(project)) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, 200);
    }
  }, [currentUser, project]);

  function handleCheckbox(e) {
    showTextarea(!textarea);
  }

  function handleContainerOnClick() {
    showModal(!modal);
  }

  function handleOnClick(e, type) {
    e.preventDefault();
    let feedback = "";
    let validation = true;

    if (textarea) {
      feedback = textareaValue;
      setErrors(adminFeedbackValidation(textareaValue));
      if (textareaValue.length <= 0 || textareaValue.length > 500) {
        validation = false;
      }
    } else {
      setErrors({});
    }

    if (validation) {
      sendFeedback(
        type,
        profile.user.email,
        profile.firstName,
        project.projectId,
        project.title,
        process.env.REACT_APP_GCS_IMAGE_URL + project.imageUrl[0]["url"],
        "Megosu Project Feedback!",
        feedback,
        currentUser.firstName,
        currentUser.lastName,
        currentUser.email,
        adminRating,
        (profile.user.role.id >= 3) ? adminRating * 1.25 : adminRating 
      ).then(() => {
        console.log("feedback sent");
      });

      if (type === "approve") {
        verifyProject(true, project.projectId).then(() => {
          console.log("updated project to verified!");
          setVerified(true);
          const urls = JSON.parse(sessionStorage.getItem("nextAdminProjects"));
          
          if (urls != null) {
            const nextUrl = urls.pop();
  
            sessionStorage.setItem("nextAdminProjects", JSON.stringify(urls));
            history.push(nextUrl);
          } else {
            history.goBack();
          }
        });
      }

      if (type === "comment") {
        showModal(false);
      }
    }

    if (type === "deny") {
      showDeleteProjectPopup(true);
    }
  }

  function removeProject(){
    showModal(false);
    showDeleteProjectPopup(false);
    deleteProject(project.projectId).then(() => {
      getProjectsByUploaderId(profile.profileId).then((projects) => {
        console.log("Deleted project.");    
      });
    });

    const urls = JSON.parse(sessionStorage.getItem("nextAdminProjects"));
    if (urls != null) {
      const nextUrl = urls.pop();
      sessionStorage.setItem("nextAdminProjects", JSON.stringify(urls));
      history.push(nextUrl);
    } else {
      history.goBack();
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <DocumentHead
            title={project.title + " by " +  profile.firstName + " on Megosu"}
            metaDescription={`${project.imageAltText}`}
          />
          <div className="project-detail">
            <div className="project-detail__content-container">
              {jQuery.isEmptyObject(project) ? (
                <LoadingSpinner />
              ) : (
                <>
                  <ProjectDetailsCard
                    primaryProjectImage={project.imageUrl[0].url}
                    teamMembers={teamMembers}
                    teamMembersLink={teamMembersLink}
                    projectUploader={profile}
                    currentUserIsProjectOwner={isProjectOwner}
                    uploaderMail={uploaderMail}
                    user={currentUser}
                    profileObject={profile}
                    projectObject={project}
                  />
                  <ProjectDetailsOverview
                    role={role && role}
                    projectObject={project}
                    showModal={(e) => showModal(e)}
                    modal={modal && modal}
                    feedbackDate={project.feedbackDate}
                    feedbackText={project.feedbackText}
                    projectOwnerRole={projectOwnerRole}
                    currentUserIsProjectOwner={isProjectOwner}
                    currentUser={currentUser}
                    profileObject={profile}
                  />
                </>
              )}
             
              <div
                className="project-detail__modal-container"
                style={
                  modal || verified ? { display: "flex" } : { display: "none" }
                }
              >
                <div
                  className="project-detail__modal-background"
                  onClick={handleContainerOnClick}
                />
                <div className="project-details-overview__modal">
                  <h2>Rate project</h2>
                  {errors.textArea && (
                    <div>
                      <span className="project-details-overview__error">{errors.textArea}</span>
                      <br /><br />
                    </div>
                  )}
                  <textarea
                    className="project-details-overview__textarea"
                    onChange={(e) => setTextareaValue(e.target.value)}
                    disabled={!textarea}
                  />
                  <div className="project-details-overview__checkbox-container">
                    <input
                      className="project-details-overview__checkbox"
                      defaultChecked={!textarea}
                      type="checkbox"
                      onClick={(e) => handleCheckbox(e)}
                    />
                    <label className="project-details-overview__label">
                      No comment
                    </label>
                  </div>
                  <PopUp
                    visibility={deleteProjectPopup}
                    overlay={true}
                    handleOnClickClose={() => showDeleteProjectPopup(false)}
                    popupTitle="Are you sure?"
                    popupText="Choosing deny will delete this project permanently!"
                    firstButtonTitle="No I'm not"
                    firstButtonAction={() => showDeleteProjectPopup(false)}
                    secondButtonTitle="Yes I'm sure"
                    secondButtonAction={() => removeProject()}
                  />
                  <div className="project-detail__rating-container">
                    {[...Array(adminRatingRange)].map((x, i) => (
                      <span key={i}>
                        {i >= adminRating ? (
                          <BsStar
                            className="project-detail__rating-star"
                            key={i}
                            onClick={(e) => setAdminRating(i + 1)}
                          />
                        ) : (
                          <BsStarFill
                            className="project-detail__rating-star"
                            key={i}
                            onClick={(e) => setAdminRating(i + 1)}
                          />
                        )}
                      </span>
                    ))}
                  </div>
                  {!textarea ? (
                    <div className="project-details-overview__modal-btn-container">
                      <Button
                        title="Approve"
                        type="pink"
                        onClick={(e) => handleOnClick(e, "approve")}
                      />
                      <Button
                        title="Deny"
                        type="outline"
                        onClick={(e) => handleOnClick(e, "deny")}
                      />
                    </div>
                  ) : (
                    <div className="project-details-overview__modal-btn-container">
                      <Button
                        title="Approve with feedback"
                        type="pink"
                        onClick={(e) => handleOnClick(e, "approve")}
                      />
                      <Button
                        title="Send feedback"
                        type="white"
                        onClick={(e) => handleOnClick(e, "comment")}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <RelatedProjects role={role && role} projectObject={project} user={currentUser} />
          </div>
        </>
      )}
    </>
  );
};

export default ProjectDetailPage;
